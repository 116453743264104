<template>
  <v-dialog
    :value="show"
    @input="$emit('update:show', !show)"
    max-width="1000px"
  >
    <v-card min-height="500" class="d-flex flex-column">
      <v-toolbar color="primary" dark style="max-height: 64px;">
        <v-toolbar-title>
          {{ $t("messagerie.pj.title") }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:show', !show)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="pa-4 fill-height" style="flex: 1">
        <v-container
          ref="attachment-dropzone"
          fluid
          class="attachment-dropzone d-flex justify-center align-center ma-n4 primary lighten-5 fill-height"
          @drop.prevent="addFile"
          @dragover="dragover"
          @dragleave="dragleave"
          style="min-height: 240px;"
        >
          <h1 class="font-weight-bold mb-0">
            {{ $t("messagerie.filtres.drag_to_add") }}
          </h1>
        </v-container>

        <v-btn text color="success" for="upload-file" @click="inputFocus">
          + {{ $t("actions.addFile")}}
        </v-btn>
        <label ref="label-file-upload" for="upload-file"></label>
        <input
          type="file"
          ref="file-upload"
          id="upload-file"
          @change="addFile($event, true)"
        />


        <div v-if="files.length > 0" class="mt-4">
          <h3>{{ $t("messagerie.pj.list") }}</h3>
          <v-data-table
            :headers="headersFiles"
            :items="files"
            :items-per-page="5"
            item-key="new_name"
          >
            <template v-slot:item.size="{ item }">
              {{ Units.readableFileSize(item.size) }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon class="red--text" @click="deleteFile(item)">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </div>
      </v-card-text>
    </v-card>
    <!-- &lt;!&ndash; Suppression d'un fichier &ndash;&gt;  -->
    <v-dialog v-model="dialogRemoveFile" max-width="480">
      <v-card v-if="fileToRemove">
        <v-card-title
          >{{ $t("messagerie.pj.delete_dialog.confirm_delete") }}
        </v-card-title>
        <v-card-text>
          <p>
            <v-icon color="warning" class="mr-3">mdi-alert</v-icon>
            {{ $t("messagerie.pj.delete_dialog.confirm_delete_texte") }}
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="
              fileToRemove = null;
              dialogRemoveFile = false;
            "
            >{{ $t("actions.cancel") }}
          </v-btn>
          <v-btn color="error" @click="removeFile(fileToRemove)"
            >{{ $t("actions.delete") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import HotlineBus from "@/Components/Views/Hotline/HotlineBus";
import MessagerieService from "@/Services/MessagerieService";
import { default as Units, default as UtilUnit } from "@/Utils/Units";

export default {
  name: "DialogAttachement",
  props: {
    show: Boolean,
    value: Array,
  },
  mounted() {
    HotlineBus.$on("Chat-reset-attachment", () => {
      this.files = [];
    });
  },
  data() {
    return {
      Units,
      files: [],
      dialogRemoveFile: false,
      headersFiles: [
        {
          id: 1,
          text: "Nom",
          align: "start",
          sortable: false,
          value: "name",
        },
        { id: 2, text: "Taille", value: "size" },
        { id: 3, text: "Actions", value: "actions", sortable: false },
      ],
      fileToRemove: null,
    };
  },
  methods: {
    /**
     * Fonction qui permets de retirer les fichiers trop gros et génère des toasters d'erreurs
     * @var files Les fichiers uploadés
     * @return Retourne les fichiers filtrés
     */
    removeBigFiles(files) {
      let result = [];
      files.forEach((f) => {
        if (
          [
            "video/x-msvideo",
            "video/mpeg",
            "video/ogg",
            "video/webm",
            "video/3gpp",
            "video/3gpp2",
            "video/x-matroska",
          ].includes(f.type)
        ) {
          if (f.size > 2e7) {
            this.$nError(
                this.$t("validation.maxFileSizeX", {val: UtilUnit.readableFileSize(2e7, true)})
            );
          } else {
            result.push(f);
          }
        } else {
          if (f.size > 2e6) {
            this.$nError(
                this.$t("validation.maxFileSizeX", {val: UtilUnit.readableFileSize(2e6, true)})
            );
          } else {
            result.push(f);
          }
        }
      });
      return result;
    },
    /**
     * Fonction lancée lors du drop de fichier dans la zone d'upload
     * @var e l'event
     */
    async addFile(e, fromInput = false) {
      let droppedFiles;
      if (fromInput) {
        droppedFiles = this.$refs["file-upload"].files;
      } else {
        e.preventDefault;
        this.$refs["attachment-dropzone"].classList.remove(
          "attachment-dropzone-hovered"
        );
        droppedFiles = e.dataTransfer.files;
      }
      droppedFiles = this.removeBigFiles(droppedFiles);

      if (!droppedFiles) return;
      // this tip, convert FileList to array, credit: https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
      [...droppedFiles].forEach((f) => {
        let formData = new FormData();
        formData.append("file", f);
        let file_data = {
          new_name: "file-" + this.$uuid.v4() + "." + f.name.split(".").pop(),
          size: f.size,
          name: f.name,
          type: f.type,
        };

        formData.append("new_name", file_data.new_name);
        MessagerieService.uploadFile(formData).then((res) => {
          this.files.push(file_data);
          this.$emit("input", this.files);
          this.$emit("addFile", file_data);
        });
      });
    },
    inputFocus() {
      this.$refs["label-file-upload"].click();
    },
    /**
     * Fonction lancée au drag hover de la zone de drop
     */
    dragover(event) {
      event.preventDefault();
      this.$refs["attachment-dropzone"].classList.add(
        "attachment-dropzone-hovered"
      );
    },
    /**
     * Fonction lancée au drag leave de la zone de drop
     */
    dragleave(event) {
      event.preventDefault();
      this.$refs["attachment-dropzone"].classList.remove(
        "attachment-dropzone-hovered"
      );
    },
    /**
     * Lors du clic sur l'icône poubelle ouvre une modale
     */
    deleteFile(item) {
      this.dialogRemoveFile = true;
      this.fileToRemove = item;
    },
    /**
     * Permet de supprimer un fichier
     * @var file le fichier
     */
    removeFile(file) {
      MessagerieService.removeFile({ type: "tmp", name: file.new_name }).then(
        (res) => {
          if (res.status === 200) {
            this.$emit("remove-file-fiche", file);
            this.$nSuccess(this.$t("messagerie.pj.file_deleted"));
            this.files = this.files.filter((f) => f.new_name !== file.new_name);
            this.$emit("input", this.files);
            this.dialogRemoveFile = false;
          } else {
            this.$nError(this.$t("messagerie.pj.file_not_deleted"));
          }
        }
      );
    },
  },
};
</script>

<style scoped>
#upload-file {
  display: none;
}
.attachment-dropzone {
  opacity: 0;
  position: absolute;
  height: calc(100% - 64px);
  /*pointer-events: none;*/
}
.attachment-dropzone-hovered {
  z-index: 100000;
  opacity: 1;
  background-opacity: 0.75;
  cursor: grabbing;
}
</style>
